<template>
    <div id="courseTeaching">

        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">精品案例</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">精品案例详情</span>>
                <span>思政亮点</span>
            </div>
            <ul v-infinite-scroll="load" style="overflow-y: auto;height: 920px;list-style: none;margin-top:15px;"
                infinite-scroll-immediate="false" class="ulList">
                <li style="width:100%;background:#fff;margin-top:10px;cursor: pointer;" v-for="(item, index) in conentList"
                    :key="index" @click="artisanDetailClick(item, caseName, index)">
                    <!-- 1咨询 2人物  3访谈   4视频 -->
                    <p v-if="item.label == 1"
                        style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                        资讯</p>
                    <p v-if="item.label == 2"
                        style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                        人物</p>
                    <p v-if="item.label == 3"
                        style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                        访谈</p>
                    <p v-if="item.label == 4"
                        style="width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;">
                        视频</p>

                    <div style="width:80%;margin:0 auto;">
                        <!-- <img :src="item.imgUrl" style="float:left;margin-right:20px" alt="" srcset=""> -->
                        <p style="font-size:18px;font-weight: 600;color: #333333;">{{ item.title }}</p>
                        <p v-if="item.gccType != 2"
                            style="font-size:14px;line-height:25px;margin-top:10px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;"
                            v-html="item.content"></p>
                    </div>
                    <p style="text-align:end;font-size:12px;padding-right:10px">{{ getTime(item.insertTime) }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { backUrl } from "@/js/util"
import * as echarts from 'echarts';
import { f_get_csGreatCountryCraftsmanPageList } from "@/js/homeListApi.js"
import { baseUrl } from '@/js/util.js'
import { ElMessage } from 'element-plus';
import { getTimeAgo } from '@/js/timeRules'

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const state = reactive({
            baseUrl: baseUrl,
            userId: getStorage('userId'),
            searchValue: '',
            screenItemIndex: 0,
            currentPage4: 1,
            pageSize4: 10,
            conentList: [
                // {
                //     title:'运行效果超出预期 背后暗藏大国工匠智慧 大兴国际机场设',
                //     conent:'展览位于展馆四层，从旅客视角展示了机场设计中的新理念、新方法、新技术和以“服务旅客”为中心的核心理念，揭秘航站楼兼具艺术性、便捷性、实用性背后的设计巧思。按1比100比例制作的C形柱局部展开模型为首次展出，清楚揭示了机场外层金属装饰屋面、内层金属隔热屋面、主钢结构层、室内大吊顶层各层“肌理”。大兴国际机场航站区工程设计总负责人之一、北京市建筑设计研究院有限公司设计师王亦知介绍，外层金属装饰屋面具备散热功能，在设计阶段，设计师模拟这层外皮能为内层降温4至5℃，而实际使用效果超出预期，降温超10℃',
                //     imgUrl:'',
                //     Uptime:'30分钟前'
                // },{
                //     title:'北京大兴机场国际中转流程全面恢复 便利旅客高效通关',
                //     conent:'日前，北京大兴机场中转厅国内转国际（D-I）流程正式启用，39名国内航班旅客在抵达大兴机场后，仅用时50分钟，成功衔接CZ673航班前往英国伦敦，整体流程顺畅、高效。',
                //     imgUrl:'',
                //     Uptime:'30分钟前'
                // },{
                //     title:'北京大兴机场国际会展消费功能区：“打造像大兴机场一样惊艳的项目”',
                //     conent:'人民网北京7月7日电 (记者李博)北京大兴国际机场临空经济区正推进“双枢纽”国际消费桥头堡建设，备受关注的国际会展中心和消费枢纽将如何打造？大兴机场临空经济区党工委副书记、管委会专职副主任罗伯明在7月6日召开的北京十六区“一把手”谈贯彻落实党的二十大精神系列主题新闻发布会大兴区专场上表示，大兴机场国际会展中心和消费枢纽要“打造像大兴机场一样惊艳的项目”，实现“买全球、卖全球”。',
                //     imgUrl:require('@/assets/img/index/zixun1.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'总工说大兴机场',
                //     conent:'展览位于展馆四层，从旅客视角展示了机场设计中的新理念、新方法、新技术和以“服务旅客”为中心的核心理念，揭秘航站楼兼具艺术性、便捷性、实用性背后的设计巧思。按1比100比例制作的C形柱局部展开模型为首次展出，清楚揭示了机场外层金属装饰屋面、内层金属隔热屋面、主钢结构层、室内大吊顶层各层“肌理”。大兴国际机场航站区工程设计总负责人之一、北京市建筑设计研究院有限公司设计师王亦知介绍，外层金属装饰屋面具备散热功能，在设计阶段，设计师模拟这层外皮能为内层降温4至5℃，而实际使用效果超出预期，降温超10℃',
                //     imgUrl:require('@/assets/img/index/zixun2.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'大兴机场项目经理',
                //     conent:'大兴国际机场国际会展消费片区，包括会展中心、消费枢纽与中央公园，总占地面积约7.8平方公里。其中，会展中心和消费枢纽位于大兴机场临空区东片区，距机场东北4公里，西邻南中轴延长线，两个项目分别规划位于北京城际铁路联络线礼贤站南北两侧。',
                //     imgUrl:require('@/assets/img/index/zixun1.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'通程中转更便捷,大兴机场国际中转流程全面恢复',
                //     conent:'自国际航班恢复起,大兴机场共计保障通程中转旅客两万余人,旅客最短中转衔接时间实现国际转国际45分钟、国内转国际60分钟、国际转国内60分钟。',
                //     imgUrl:require('@/assets/img/index/zixun3.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'天津货运航空首条定期航线进驻北京大兴国际机场',
                //     conent:'央广网天津7月7日消息(记者周思杨)记者从天津港保税区管委会获悉,日前,天津货运航空有限公司(以下简称“天津货运航空”)一架B737全货机满载货物自北京大兴国际机场飞抵安徽芜湖宣州机场',
                //     imgUrl:require('@/assets/img/index/zixun4.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'“兴邀天下” | 北京市进出口企业走进大兴机场临空区',
                //     conent:'7月6日,大兴区投资促进服务中心联合大兴机场临空区(大兴)管委会共同举办了“兴邀天下”北京市进出口企业协会大兴行活动,北京市进出口企业协会副会长兼秘书长尹军及30多家外贸企业代表携手走进大兴。',
                //     imgUrl:require('@/assets/img/index/zixun5.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'蝉联!唯一!满意度最佳机场 - 新京报 - 好新闻,无止境',
                //     conent:'自投运以来,大兴机场深入践行新发展理念,秉持共商、共建、共治、共赢理念,打造机场命运共同体,持续构建以服务航空公司为核心的机场发展生态圈。',
                //     imgUrl:require('@/assets/img/index/zixun6.png'),
                //     Uptime:'30分钟前'
                // },{
                //     title:'全面建设宜居宜业新大兴 繁荣开放新国门',
                //     conent:'大兴区委书记王有国,区委副书记、区长刘学亮,区委常委、常务副区长刘洋,大兴机场临空区党工委副书记、管委会专职副主任罗伯明等领导出席,围绕“全面建设宜居宜业新大兴、繁荣开放新国门”主题,',
                //     imgUrl:require('@/assets/img/index/zixun4.png'),
                //     Uptime:'30分钟前'
                // }
            ],
            id: getStorage('caseSharingId'),
            caseName: getStorage('caseSharingName'),


            num: 2,

        })
        const methods = {
            //获取思政亮点分页数据
            // gccType 0全部 1原创 2转载
            getconentList() {
                let datas = {
                    caseSharingId: state.id,
                    title: '',
                    gccType: 0,
                    pageIndex: state.currentPage4,
                    pageSize: state.pageSize4,
                }
                f_get_csGreatCountryCraftsmanPageList(datas).then(res => {
                    if (res.data.code == 1) {
                        state.conentList = res.data.data.list;
                        console.log(res.data.data.list);
                    }
                })
            },
            getTime(s) {
                return getTimeAgo(s)
            },
            //下拉加载更多
            load() {
                let datas = {
                    caseSharingId: state.id,
                    title: '',
                    gccType: 0,
                    pageIndex: state.num++,
                    pageSize: state.pageSize4,
                }
                f_get_csGreatCountryCraftsmanPageList(datas).then((res) => {
                    if (res.data.code == 1) {
                        res.data.data.list.forEach(item => {
                            if (item) {
                                state.conentList.push(item)
                            }
                        });
                        // state.count += res.data.data.totalCount.Value
                        console.log(state.conentList, "思政亮点分页数据222");
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },


            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick() {
                router.push('/home/boutiqueCases');
            },
            detailsClick() {
                router.push('/home/casesDetail');
            },
            artisanDetailClick(item, caseName, index) {
                setStorage('artisan', item)
                setStorage('artiIndex', index)
                setStorage('conentList', state.conentList)
                if (item.gccType == 2) {
                    window.open(item.content, "_blank")
                } else {
                    router.push({ path: '/home/artisanDetail', query: { caseName: caseName } });
                }
            },
        }
        onMounted(() => {
            methods.getconentList();//获取思政亮点分页数据

        })
        return {
            ...toRefs(state),
            ...methods,
        };
    }
}
</script>

<style  lang="scss" scoped>
#courseTeaching {
    height: 100%;
    position: relative;
    background: #F7F8FA;

    .box {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;

        :deep .el-input__inner {
            border-radius: 50px !important;
            border: none;
        }

        .breadCrumbs {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 12px;
        }

        .ulList::-webkit-scrollbar {
            width: 4px;
            height: 0;
            background-color: transparent;
        }

        .ulList::-webkit-scrollbar-thumb {
            border-radius: 2px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #ffffff;
        }

        .ulList::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 1px transparent;
            border-radius: 10px;
            background-color: transparent;
        }
    }
}</style>