import axios from 'axios';
import {baseUrl} from '@/js/util.js'

// 获取1、行业动态 2、最新政策 3、校内公告
export const get_homeNoticeSettingList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_get_homeNoticeSettingList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取课程教学列表
export const get_coursePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_get_coursePageList`,
        params:datas,
    })
}
//获取专业
export const get_select_majorList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_majorList`,
        params:datas,
    })
}
//获取行业
export const get_select_industryList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_industryList`,
        params:datas,
    })
}
//获取课程详情
export const get_courseDataInfo=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_get_courseDataInfo`,
        params:datas,
    })
}
//获取课程详情下的教师团队
export const get_courseTeacherTeamList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_courseTeacherTeamList`,
        params:datas,
    })
}
//获取课程详情下的课程评价
export const get_courseEvaluationPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_courseEvaluationPageList`,
        params:datas,
    })
}
//获取课程详情下的课程目录
export const get_courseCatalogueList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_courseCatalogueList`,
        params:datas,
    })
}
//添加评价
export const F_insert_courseEvaluation=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_insert_courseEvaluation`,
        data:datas,
    })
}
//获取学生所有订阅课程
export const get_studentCourseList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentCourseList`,
        params:datas,
    })
}
//添加/取消订阅课程
export const F_Insert_subscribeCourse=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_subscribeCourse`,
        data:datas,
    })
}
//获取课程实训分页数据
export const get_digitalTrainingPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_digitalTrainingPageList`,
        params:datas,
    })
}
//添加浏览记录
export const F_Insert_browsingHistory=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_browsingHistory`,
        data:datas,
    })
}
//获取阶段
export const get_select_stageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_stageList`,
        params:datas,
    })
}
//获取课程实训详情数据
export const get_digitalTrainingDataInfo=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_digitalTrainingDataInfo`,
        params:datas,
    })
}
//添加实训次数
export const F_Insert_trainingRecord=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_trainingRecord`,
        data:datas,
    })
}
//学生用户去课程实训
export const f_go_courseTraining=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_go_courseTraining`,
        params:datas,
    })
}
//获取系统实训分页数据
export const f_get_systemExperiencePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_systemExperiencePageList`,
        params:datas,
    })
}
//获取系统实训体验下拉搜索
export const get_select_systemExperience=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_systemExperience`,
        params:datas,
    })
}
//获取系统体验习题数据
export const f_get_systemExperienceExercises=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_systemExperienceExercises`,
        params:datas,
    })
}
//学生用户去系统体验实训
export const f_go_systemExperienceTraining=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_go_systemExperienceTraining`,
        params:datas,
    })
}
//获取考评中心 考试统计
export const f_get_examinationStatistics=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_examinationStatistics`,
        params:datas,
    })
}
//获取考试的年度
export const get_select_examinationYear=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_examinationYear`,
        params:datas,
    })
}
//获取年级下拉列表搜索框
export const get_select_gradeList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_gradeList`,
        params:datas,
    })
}
//获取科目下拉列表搜索框
export const get_select_subjectList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_subjectList`,
        params:datas,
    })
}
//获取班级下拉列表搜索框
export const get_select_classNumList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_classNumList`,
        params:datas,
    })
}
//获取考评中心 课程实训统计
export const f_get_digitalTrainingStatistics=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_digitalTrainingStatistics`,
        params:datas,
    })
}
//学生所有试卷预览
export const f_get_ShowJobHistory=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_ShowJobHistory`,
        params:datas,
    })
}
//获取考评中心 系统实训统计
export const f_get_systemExperienceStatistics=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_systemExperienceStatistics`,
        params:datas,
    })
}
//查看思政亮点分页数据
export const f_get_csGreatCountryCraftsmanPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_csGreatCountryCraftsmanPageList`,
        params:datas,
    })
}
//查看专业讲堂数据
export const f_get_csMajorClassRoom=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_csMajorClassRoom`,
        params:datas,
    })
}

export let guid=()=> {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
  }

//添加/修改课程内容文件
export const f_insert_courseContentFile=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_insert_courseContentFile`,
        data:datas,
    })
}
//添加/修改课程课件
export const f_insert_courseWareFile=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_insert_courseWareFile`,
        data:datas,
    })
}

//获取用户个人信息
export const f_get_userInfo=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_get_userInfo`,
        params:datas,
    })
}

//修改用户密码
export const f_update_userPwd=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_user/f_update_userPwd`,
        data:datas,
    })
}

//获取学生任务
export const f_get_studentTaskPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentTaskPageList`,
        params:datas,
    })
}

//获取用户所有未读信息
export const f_get_userMessage=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_get_userMessage`,
        params:datas,
    })
}

//获取学生课程信息
export const f_get_studentCourse=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentCourse`,
        params:datas,
    })
}


//修改用户消息状态
export const f_update_userMsgStatus=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_update_userMsgStatus`,
        params:datas,
    })
}

//学生用户去做课后作业
export const f_go_courseExercises=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_go_courseExercises`,
        params:datas,
    })
}
//学生用户去考试
export const f_go_examination=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_go_examination`,
        params:datas,
    })
}
//获取课程详情下的课程思政
export const f_get_courseIdeologyPoliticsDataInfo=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/f_get_courseIdeologyPoliticsDataInfo`,
        params:datas,
    })
}
//学生试卷查看
export const f_get_examinationPaperPreview=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_examinationPaperPreview`,
        params:datas,
    })
}
//提交习题/作业
export const f_submit_courseExercises=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_user/f_submit_courseExercises`,
        data:datas,
    })
}

//学生用户去做习题
export const f_go_exercises=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_go_exercises`,
        params:datas,
    })
}

//添加播放次数
export const F_Insert_playnumstatistics=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_playnumstatistics`,
        data:datas,
    })
}


//添加播放记录
export const F_Insert_playRecording=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/Home/F_Insert_playRecording`,
        data:datas,
    })
}

//获取指定课程内容的播放进度
export const F_Get_playRecordingData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_playRecordingData`,
        params:datas,
    })
}

//提交系统实训/课程实训
export const f_submit_training=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_user/f_submit_training`,
        data:datas,
    })
}

//提交考试
export const f_submit_examination=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_user/f_submit_examination`,
        data:datas,
    })
}
// 获取轮播图
export const F_Get_shufflingPictureList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_shufflingPictureList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取课程实训下拉搜索
export const get_select_digitalTraining=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_digitalTraining`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}