export const getTimeAgo = (dateString) => {
  const targetDate = new Date(dateString);
  const now = new Date();
  const timeDifference = now - targetDate;
  const minutesAgo = Math.floor(timeDifference / (1000 * 60));
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (minutesAgo <= 59) {
    return `${minutesAgo}分钟前`;
  } else if (hoursAgo <= 23) {
    return `${hoursAgo}小时前`;
  } else if (daysAgo <= 30) {
    return `${daysAgo}天前`;
  } else {
    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1; // Month is zero-based
    const day = targetDate.getDate();
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  }
}
